.onboarding-kyc-fica {
    width: clamp(300px, 90%, 1000px);
    margin: 80px auto 0px auto;
    font-family: "Poppins", sans-serif;
    color: #454f5b;
}

.info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
}

.info-section.reverse {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.icon-container,
.content {
    width: 440px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.icon-container img {
    width: 300px;
    height: 350px;
    object-fit: contain;
}

.content h2 {
    font-family: "Fredoka One", cursive;
    font-size: 35px;
    margin-bottom: 20px;
    color: #454f5b;
    text-align: left;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.content p,
.content ul {
    font-size: 18px;
    margin-bottom: 10px;
}

.content ul {
    padding-left: 0px;
    list-style-type: none;
}

.content li {
    margin-bottom: 5px;
}

.video-section {
    margin-top: 60px;
}

.video-section-header {
    display: flex;
    justify-content: center;
}

.video-section-header h2 {
    margin-bottom: 10px;
}

.tips-section {
    margin-top: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tips-section h2 {
    margin-bottom: 10px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tips-text {
    text-align: center;
    margin-bottom: 30px;
}

.card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card-link {
    width: calc(25% - 20px);
    margin-bottom: 20px;
    text-decoration: none;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.card-image:hover {
    transform: scale(1.05);
}

@media (max-width: 940px) {
    .info-section,
    .info-section.reverse {
        flex-direction: column;
        align-items: center;
    }

    .icon-container,
    .content {
        width: 100%;
        max-width: 440px;
    }

    .icon-container {
        margin-bottom: 20px;
    }

    .content {
        text-align: left;
    }

    .content ul {
        text-align: left;
    }

    .icon-container img {
        width: 170px;
        height: 203px;
    }

    .content h2 {
        font-size: 20px;
        padding: 0px;
    }

    .content p,
    .content ul {
        font-size: 16px;
    }

    .card-container {
        justify-content: space-between;
        margin: 0 -10px;
    }

    .card-link {
        width: calc(50% - 20px);
        max-width: 180px;
        margin: 0 10px 20px;
    }

    .onboarding-kyc-fica {
        margin: 60px auto -30px auto;
    }

    

    .tips-section
    {
        text-align: left;
    }

    .tips-section h2
    {
        text-align: left !important;
        align-self: flex-start;
        padding: 0px;
    }

    .tips-text
    {
        text-align: left;
    }
}